import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const MDASUrl = process.env.REACT_APP_MDAS_URL;
const otherUrl = process.env.REACT_APP_OTHER_MODULES_URL;

// Define a service using a base URL and expected endpoints
export const meterConfigurationApi = createApi({
  reducerPath: 'meterConfigurationApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    //Meter configuration APIs

    getMeterConfigurationList: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_BASE_URL}/cmd-exe-legacy/api/hes/hcel/meter-configuration-data/`,
        params: params
      }),
      providesTags: ['meterConfigList'],
      transformResponse: (response) => ({
        data: response.data.result.results,
        nextCursor: response.data.result.next,
        previousCursor: response.data.result.previous
      })
    }),
    downloadMeterConfigurationRequestReport: builder.query({
      query: (params) => ({
        url: `${MDASUrl}/api/hes/dlms/meter-config-request_report-download/`,
        params: params
      })
    }),
    downloadMeterConfigurationReport: builder.query({
      query: (params) => ({
        url: `${MDASUrl}/api/hes/dlms/meter-config-report-download/`,
        params: params
      })
    }),

    // Meter Configuration Data Modal APIs
    getMeterMetaData: builder.query({
      query: (params) => ({
        url: `${otherUrl}/api/v1/get/gis/search`,
        params: params
      })
    }),
    editCommunicationProtocol: builder.query({
      query: (params) => ({
        url: `${MDASUrl}/api/hes/mdm/meter-communication-protocol/`,
        params: {
          meter: params.meter,
          communication_protocol: params.communication_protocol
        }
      })
    }),
    pingMeter: builder.mutation({
      query: (data) => ({
        url: `https://wfm-integration.sangai.polarisgrids.com/device-ping`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['meterConfigList']
    })
  })
});
export const {
  useGetMeterConfigurationListQuery,
  useDownloadMeterConfigurationRequestReportQuery,
  useLazyDownloadMeterConfigurationReportQuery,
  useGetMeterMetaDataQuery,
  useLazyEditCommunicationProtocolQuery,
  usePingMeterMutation
} = meterConfigurationApi;
