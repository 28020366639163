import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  collapsed: false,
  isMobileSidebarOpen: false,
  isCommandDropdown: false
};
const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setCollapsed: (state, action) => {
      state.collapsed = action.payload;
    },
    setIsMobileSidebarOpen: (state, action) => {
      state.isMobileSidebarOpen = action.payload;
    },
    setIsCommandDropdown: (state, action) => {
      state.isCommandDropdown = action.payload;
    }
  }
});

export const { setCollapsed, setIsMobileSidebarOpen, setIsCommandDropdown } =
  layoutSlice.actions;
export default layoutSlice.reducer;
