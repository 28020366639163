import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../../../styles/layout.scss';
import HesIcon from '@/assets/images/svg/hes.svg';
import MDMSIcon from '@/assets/images/svg/mdms.svg';
import PrePaidConfigIcon from '@/assets/images/svg/prepaid-congif.svg';
import FacotryFileIcon from '@/assets/images/svg/factory-push.svg';
import PrepaidDashIcon from '@/assets/images/svg/prepaid-dashboard.svg';
import DtFeederIcon from '@/assets/images/svg/dt-feeder.svg';
import BillingIcon from '@/assets/images/svg/billing-dashboard.svg';
import ReportIcon from '@/assets/images/svg/report.svg';
import DashIcon from '@/assets/images/svg/data-acquisition.svg';
import VeeIcon from '@/assets/images/svg/vee.svg';
import SlaReports from '@/assets/images/svg/sla-reports.svg';

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const collapsed = useSelector((state) => state.layout.collapsed);

  const isMobileSidebarOpen = useSelector(
    (state) => state.layout.isMobileSidebarOpen
  );

  const toggleIcon = {
    HES: HesIcon,
    MDMS: MDMSIcon,
    'prepaid-configuration': PrePaidConfigIcon,
    'prepaid-dashboard': PrepaidDashIcon,
    'factory-push': FacotryFileIcon,
    Dashboard: DashIcon,
    report: ReportIcon,
    'billing-dashboard': BillingIcon,
    'dt-feeder-monitoring': DtFeederIcon,
    vee: VeeIcon,
    'SLA-Reports': SlaReports
  };

  const newArray = JSON.parse(localStorage.getItem('routes'));

  return (
    <div className="pt-1">
      <Sidebar
        collapsed={collapsed}
        style={{
          border: '0px solid'
        }}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, active }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: active ? 'white' : undefined,
                  backgroundColor: active ? '#7367f0' : undefined,
                  '&:hover': {
                    backgroundColor: '#7367f0',
                    color: 'white'
                  },
                  borderRadius: '10px',
                  margin: '5px'
                };
            }
          }}
        >
          {newArray.map((route) => (
            <MenuItem
              key={route.key}
              id={route.id}
              icon={
                <img
                  src={toggleIcon[route.title] || HesIcon}
                  alt={`${route.title} icon`}
                  width="30"
                  height={'30'}
                />
              }
              // component={route.title!==<Link to={route.navLink} />}
              onClick={() => {
                route.navLink === '/utility/uppcl/vee'
                  ? window.open('https://vee.gomatimvvnl.in/vee/', '_blank')
                  : route.navLink === '/utility/uppcl/reports'
                  ? window.open(
                      'https://grafana.mdms.gomatimvvnl.in/dashboards/f/adsf0b7ody96oa/',
                      '_blank'
                    )
                  : navigate(route.navLink);
              }}
              active={location.pathname === route.navLink}
              data-bs-dismiss={isMobileSidebarOpen ? 'offcanvas' : ''}
            >
              {route.title.length > 15 ? (
                <div
                  className="marquee-container"
                  style={{
                    fontWeight: '500'
                  }}
                >
                  <div className="marquee-text">
                    {route.title.replaceAll('-', ' ').toUpperCase()}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    fontWeight: '500'
                  }}
                >
                  {route.title.replaceAll('-', ' ').toUpperCase()}
                </div>
              )}
            </MenuItem>
          ))}
        </Menu>
      </Sidebar>
    </div>
  );
}

export default SideBar;
