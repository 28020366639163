import { loginApi } from '../api/loginSlice';
import { forgotPasswordApi } from '../api/forgot-passwordSlice';
import { commandHistoryApi } from '../api/hes/command-historySlice';
import { dropdownsApi } from '../api/hes/drop-downSlice';
import logoutApi from '../api/logoutSlice';
import { pushDataApi } from '../api/hes/push-dataSlice';
import { meterConfigurationApi } from '../api/hes/meter-configurationSlice';
import { energyConsumptionApi } from '../api/mdms/energy-consumptionSlice';
import { operationalStatsApi } from '../api/mdms/operational-statisticsSlice';
import { loadsApi } from '../api/mdms/loadSlice';
import { userConsumptionApi } from '../api/mdms/userConsumptionSlice';
// import hesApi from '../api/hes/hesApi';
import { energyConsumptionApiV2 } from '../api/mdms/energy-consumptionSliceV2';
import { slaReportsApi } from '@/api/sla-reports';
import { gisApi } from '@/api/gis/gis-slice';
import { HealthPortalApi } from '@/api/coliving/health-portalSlice';
import { selectSiteApi } from '@/api/coliving/selectSiteNameSlice';
import { userAccessPanelApi } from '@/api/user-access-panel';
import { tariffDataApi } from '@/api/prepaid-config/tariffSlice';
import { factoryPush } from '@/api/factory-push';
import { holidaysApi } from '@/api/prepaid-config/holidaysSlice';
import { featuresAccessPanelApi } from '@/api/prepaid-config/features-access-panelSlice';
import { arrearConfigApi } from '@/api/prepaid-config/arrearConfigSlice';
import { prepaidDashboardSlice } from '@/api/prepaid-dashboard';
import { supersetApi } from '@/api/superset';
import { dtFeederMonitorSlice } from '@/api/dt-feeder-monitoring';
import { billingDashboardSlice } from '@/api/billing-dashboard';
import { timeDriftAPI } from '@/api/hes/timeDrift';
import { cognitoUserAccessPanelApi } from '@/api/cognito-user-access-panel';

const middleware = [
  loginApi.middleware,
  forgotPasswordApi.middleware,
  slaReportsApi.middleware,
  commandHistoryApi.middleware,
  dropdownsApi.middleware,
  pushDataApi.middleware,
  meterConfigurationApi.middleware,
  logoutApi.middleware,
  energyConsumptionApi.middleware,
  operationalStatsApi.middleware,
  loadsApi.middleware,
  userConsumptionApi.middleware,
  // hesApi.middleware,
  energyConsumptionApiV2.middleware,
  gisApi.middleware,
  HealthPortalApi.middleware,
  selectSiteApi.middleware,
  userAccessPanelApi.middleware,
  tariffDataApi.middleware,
  factoryPush.middleware,
  holidaysApi.middleware,
  featuresAccessPanelApi.middleware,
  cognitoUserAccessPanelApi.middleware,
  arrearConfigApi.middleware,
  prepaidDashboardSlice.middleware,
  timeDriftAPI.middleware,
  supersetApi.middleware,
  dtFeederMonitorSlice.middleware,
  billingDashboardSlice.middleware
];

export default middleware;
