import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_OTHER_MODULES_URL;

export const selectSiteApi = createApi({
  reducerPath: 'selectSiteApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getGISAssetsTillDTR: builder.query({
      query: (params) => ({
        url: `${url}/api/v1/get/gis/project/data`,
        params: params,
      }),
    }),
  }),
});
export const { useGetGISAssetsTillDTRQuery } = selectSiteApi;
