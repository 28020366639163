import { combineReducers } from '@reduxjs/toolkit';
import { loginApi } from '../api/loginSlice';
import { forgotPasswordApi } from '../api/forgot-passwordSlice';
import { commandHistoryApi } from '../api/hes/command-historySlice';
import { dropdownsApi } from '../api/hes/drop-downSlice';
import logoutApi from '../api/logoutSlice';
import {
  utilityMDASAssetListReducer,
  utilityMDASDlmsCommandReducer
} from '../app/redux/commandExecutionSlice';
import { currentSelectedModuleReducer } from './redux/previousSelectedModuleSlice';
import { MDMSHierarchyProgressReducer } from './redux/mdmsHeirarchySlice';
import { pushDataApi } from '../api/hes/push-dataSlice';
import { meterConfigurationApi } from '../api/hes/meter-configurationSlice';
import layoutReducer from './redux/layoutSlice';
import { energyConsumptionApi } from '../api/mdms/energy-consumptionSlice';
import { operationalStatsApi } from '../api/mdms/operational-statisticsSlice';
import { loadsApi } from '../api/mdms/loadSlice';
import { userConsumptionApi } from '../api/mdms/userConsumptionSlice';
// import hesApi from '../api/hes/hesApi';
import { energyConsumptionApiV2 } from '../api/mdms/energy-consumptionSliceV2';
import { slaReportsApi } from '@/api/sla-reports';
import utilityGISMaps from './redux/gisSlice';
import { gisApi } from '@/api/gis/gis-slice';
import { HealthPortalApi } from '@/api/coliving/health-portalSlice';
import { userAccessPanelApi } from '@/api/user-access-panel';
import { tariffDataApi } from '@/api/prepaid-config/tariffSlice';
import { factoryPush } from '@/api/factory-push';
import { holidaysApi } from '@/api/prepaid-config/holidaysSlice';
import { featuresAccessPanelApi } from '@/api/prepaid-config/features-access-panelSlice';
import { arrearConfigApi } from '@/api/prepaid-config/arrearConfigSlice';
import { prepaidDashboardSlice } from '@/api/prepaid-dashboard';
import { supersetApi } from '@/api/superset';
import { dtFeederMonitorSlice } from '@/api/dt-feeder-monitoring';
import { billingDashboardSlice } from '@/api/billing-dashboard';
import { timeDriftAPI } from '@/api/hes/timeDrift';
import { cognitoUserAccessPanelApi } from '@/api/cognito-user-access-panel';

const rootReducer = combineReducers({
  [loginApi.reducerPath]: loginApi.reducer,
  [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
  [commandHistoryApi.reducerPath]: commandHistoryApi.reducer,
  [pushDataApi.reducerPath]: pushDataApi.reducer,
  [logoutApi.reducerPath]: logoutApi.reducer,
  [meterConfigurationApi.reducerPath]: meterConfigurationApi.reducer,
  [energyConsumptionApi.reducerPath]: energyConsumptionApi.reducer,
  [operationalStatsApi.reducerPath]: operationalStatsApi.reducer,
  [loadsApi.reducerPath]: loadsApi.reducer,
  [userConsumptionApi.reducerPath]: userConsumptionApi.reducer,
  // [hesApi.reducerPath]: hesApi.reducer,
  [energyConsumptionApiV2.reducerPath]: energyConsumptionApiV2.reducer,
  [slaReportsApi.reducerPath]: slaReportsApi.reducer,
  [gisApi.reducerPath]: gisApi.reducer,
  [HealthPortalApi.reducerPath]: HealthPortalApi.reducer,
  [userAccessPanelApi.reducerPath]: userAccessPanelApi.reducer,
  [tariffDataApi.reducerPath]: tariffDataApi.reducer,
  [factoryPush.reducerPath]: factoryPush.reducer,
  [cognitoUserAccessPanelApi.reducerPath]: cognitoUserAccessPanelApi.reducer,
  [holidaysApi.reducerPath]: holidaysApi.reducer,
  [featuresAccessPanelApi.reducerPath]: featuresAccessPanelApi.reducer,
  [arrearConfigApi.reducerPath]: arrearConfigApi.reducer,
  layout: layoutReducer,
  [timeDriftAPI.reducerPath]: timeDriftAPI.reducer,
  [dropdownsApi.reducerPath]: dropdownsApi.reducer,
  [prepaidDashboardSlice.reducerPath]: prepaidDashboardSlice.reducer,
  [supersetApi.reducerPath]: supersetApi.reducer,
  [dtFeederMonitorSlice.reducerPath]: dtFeederMonitorSlice.reducer,
  [billingDashboardSlice.reducerPath]: billingDashboardSlice.reducer,
  utilityMDASAssetList: utilityMDASAssetListReducer,
  utilityMDASDlmsCommand: utilityMDASDlmsCommandReducer,
  currentSelectedModule: currentSelectedModuleReducer,
  MDMSHierarchyProgress: MDMSHierarchyProgressReducer,
  utilityGISMaps: utilityGISMaps
});

export default rootReducer;
