// /api/hes/mdm/blockload/
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

export const timeDriftAPI = createApi({
  reducerPath: 'timeDriftAPI',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getTimeDrift: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_BASE_URL}/time-drift/api/hes/rtc-drift-report`,
        params: params
      }),
      transformResponse: (response) => {
        return response.data.message;
      }
    })
  })
});

export const { useGetTimeDriftQuery } = timeDriftAPI;
