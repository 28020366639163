import React, { useEffect, useState } from 'react';
import { setIsMobileSidebarOpen } from '../../../app/redux/layoutSlice';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setCollapsed } from '../../../app/redux/layoutSlice';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../../api/logoutSlice';

import UserProfileTab from './userProfileWrapper';
import NavbarSearch from './NavbarSearch';
import { Search, X } from 'react-feather';
import { capitalize } from '@/utils';

import userImg from '@/assets/images/mock-user.png';
import { useLazySignOutUserQuery } from '@/api/cognito-user-access-panel';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [logout, logoutResponse] = useLogoutMutation();
  const [userProfile, setUserProfile] = useState(false);
  const refreshToken = localStorage.getItem('refreshToken');
  const idToken = localStorage.getItem('idToken');

  const project = location.pathname.split('/')[3];
  const [userDetails, setUserDetails] = useState({});

  const [signOut] = useLazySignOutUserQuery();

  const dispatch = useDispatch();
  const toggleCollapse = () => {
    dispatch(setCollapsed(false));
  };
  const handleIsMobileSidebarOpen = () => {
    dispatch(setIsMobileSidebarOpen(true));
    toggleCollapse();
  };

  const handleResize = (event) => {
    if (event.target.innerWidth > 991) {
      dispatch(setIsMobileSidebarOpen(false));
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    try {
      if (idToken) {
        const userDetails = jwtDecode(idToken);
        setUserDetails(userDetails);
      }
    } catch (error) {
      toast('Failed to decode id token or retrieve user details:', {
        hideProgressBar: true,
        type: error
      });
    }
  }, []);

  const handleDropDown = () => {
    setOpenDropDown(!openDropDown);
  };

  const userProfileModal = () => {
    setUserProfile(!userProfile);
    setOpenDropDown(false);
  };
  return (
    <>
      <nav className="navbar border">
        <div className="container-fluid">
          <div className="menu-btn-container d-flex">
            <button
              className={`btn d-lg-none ${
                openSearchBar ? 'd-none d-sm-block' : 'd-block'
              }`}
              id="menu-btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#drawer"
              aria-controls="drawer"
              onClick={handleIsMobileSidebarOpen}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="ficon"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </button>
            {/* <h3 className="d-none d-sm-block">Avdhaan</h3> */}
            <div className="d-none d-lg-flex align-items-center gap-1 justify-content-center">
              <img
                src={`${process.env.PUBLIC_URL}/polaris-logo.svg`}
                alt="Avdhaan Logo"
                style={{
                  width: '30px',
                  height: '30px'
                }}
              />
              <h1
                style={{
                  fontWeight: 'bold',
                  color: '#0A3690',
                  margin: '0px',
                  fontFamily: 'sans-serif'
                }}
              >
                Avdhaan
              </h1>
              <div
                style={{
                  borderLeft: '2px solid #0a369054',
                  height: '20px'
                }}
              ></div>
              <h2
                style={{
                  marginBottom: 0,
                  color: '#0a3690b0'
                }}
              >
                Sangai
              </h2>
            </div>
          </div>
          <div className="d-flex flex-grow-1 justify-content-end">
            {openSearchBar && <NavbarSearch />}
          </div>
          <ul className="navbar-nav d-flex flex-row align-items-center gap-1">
            {project === 'mdms' && (
              <Link className="d-flex justify-content-center">
                {openSearchBar ? (
                  <X
                    onClick={() => setOpenSearchBar(false)}
                    className="text-danger"
                  />
                ) : (
                  <Search onClick={() => setOpenSearchBar(true)} />
                )}
              </Link>
            )}
            <div
              className={`navbar-nav ${
                openSearchBar ? 'd-none d-sm-flex' : 'd-flex'
              } flex-row align-items-center gap-1`}
            >
              {userDetails['cognito:groups']?.[0] === 'admin' && (
                <Link to={'/access-control-panel'}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="ficon"
                  >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                  </svg>
                </Link>
              )}
              <li className="text-end">
                <b>
                  {capitalize(userDetails.given_name) +
                    ' ' +
                    capitalize(userDetails.family_name)}
                </b>
                <br />
                <small className="d-block">
                  {userDetails['cognito:groups']?.[0]}
                </small>
              </li>
              <li className="dropdown-user nav-item dropdown">
                <a
                  aria-haspopup="true"
                  className="nav-link dropdown-user-link"
                  aria-expanded="false"
                  // onClick={(event) => handleDropDown(event)}
                >
                  <div>
                    <img
                      src={userImg}
                      alt="avatarImg"
                      height="40"
                      width="40"
                      onClick={handleDropDown}
                      style={{
                        backgroundColor: 'transparent',
                        borderRadius: '50%', // Makes the image circular
                        objectFit: 'cover' // Ensures the image fits properly
                      }}
                    />
                    {/* <span className="avatar-status-online"></span> */}
                  </div>
                </a>
                {openDropDown && (
                  <Card
                    tabIndex="-1"
                    role="menu"
                    // aria-hidden="true"
                    className="position-absolute end-0 mt-1 "
                    style={{
                      zIndex: '150'
                    }}
                  >
                    <CardBody
                      className="p-0"
                      style={{
                        width: '170px'
                      }}
                    >
                      <ListGroup>
                        <ListGroupItem
                          style={{
                            width: '170px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          tag="button"
                          action
                          className="dropdown-item"
                          onClick={userProfileModal}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="me-1"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                          <span className="align-middle">User Profile</span>
                        </ListGroupItem>
                        <ListGroupItem
                          tag="button"
                          action
                          className="dropdown-item"
                          onClick={() => {
                            localStorage.clear();
                            signOut();
                            navigate('/', { replace: true });
                          }}
                          style={{
                            width: '170px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="me-1"
                          >
                            <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                            <line x1="12" y1="2" x2="12" y2="12"></line>
                          </svg>
                          <button className="align-middle logout-button">
                            Logout
                          </button>
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  </Card>
                )}
              </li>
            </div>
          </ul>
        </div>
      </nav>
      <Modal
        isOpen={userProfile}
        toggle={userProfileModal}
        className={`modal-md modal-dialog-centered`}
      >
        <ModalHeader toggle={userProfileModal}>User Profile</ModalHeader>
        <ModalBody>
          <UserProfileTab />
        </ModalBody>
      </Modal>
    </>
  );
}

export default Navbar;
