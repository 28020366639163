import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../utils/baseQueryWithAuth';

const url = process.env.REACT_APP_HEALTH_PORTAL;

export const HealthPortalApi = createApi({
  reducerPath: 'HealthPortalApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    postAssetHealthCount: builder.mutation({
      query: (data) => ({
        url: `${url}/health-portal/counts`,
        method: 'POST',
        body: data,
      }),
    }),
    postDcusUpDown: builder.mutation({
      query: (data) => ({
        url: `${url}/health-portal/dcus`,
        method: 'POST',
        body: data,
      }),
    }),
    postSitesUpDown: builder.mutation({
      query: (data) => ({
        url: `${url}/health-portal/sites`,
        method: 'POST',
        body: data,
      }),
    }),
    // postMeterList: builder.mutation({
    //   query: (data) => ({
    //     url: `${url}/health-portal/meters`,
    //     method: 'POST',
    //     body: data,
    //   }),
    // }),
  }),
});
export const {
  usePostAssetHealthCountMutation,
  usePostDcusUpDownMutation,
  usePostSitesUpDownMutation,
  // usePostMeterListMutation,
} = HealthPortalApi;
