import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const supersetUrl = 'https://reports.mdms.gomatimvvnl.in';
const supersetApiUrl = supersetUrl + '/api/v1/security';
const dashboardId = '6742aa11-a75a-4e91-990b-3b889063d1dd'; // replace with your dashboard id

export const supersetApi = createApi({
  reducerPath: 'supersetApi',
  baseQuery: fetchBaseQuery({ baseUrl: supersetApiUrl }),
  endpoints: (builder) => ({
    getAccessToken: builder.query({
      query: () => ({
        url: '/login',
        method: 'POST',
        body: {
          password: 'admin1',
          provider: 'db',
          refresh: true,
          username: 'admin1'
        }
      })
    }),
    getGuestToken: builder.mutation({
      query: (accessToken) => ({
        url: '/guest_token/',
        method: 'POST',
        body: {
          resources: [{ type: 'dashboard', id: dashboardId }],
          rls: [],
          user: {
            username: 'polaris',
            first_name: 'polaris',
            last_name: 'polaris'
          }
        },
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
    })
  })
});

export const { useGetAccessTokenQuery, useGetGuestTokenMutation } = supersetApi;
