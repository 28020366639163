import { lazy } from 'react';
import HesIcon from '@/assets/images/svg/hes.svg';
import PrePaidConfigIcon from '@/assets/images/svg/report.svg';
import FacotryFileIcon from '@/assets/images/svg/scheduling.svg';
import PrepaidDashIcon from '@/assets/images/svg/energy-audit.svg';

const MfaVerify = lazy(() => import('@/pages/verifyMfa'));

const AccessControlPanel = lazy(() => import('@/pages/access-control-panel'));

const ForceChangePassword = lazy(() => import('@/pages/forceChangePassword'));

const SetupMfa = lazy(() => import('@/pages/setupMfa'));
const LazyPrepaidConfig = lazy(() =>
  import('@/pages/utility/module/prepaid-config')
);
const LazyFactoryPush = lazy(() =>
  import('@/pages/utility/module/factory-push')
);
const LazyPrepaidDashboard = lazy(() =>
  import('@/pages/utility/module/prepaid-dashboard')
);

const LazyDTFeederMonitoring = lazy(() =>
  import('@/pages/utility/module/dtr-feeder-monitoring')
);

const LazyDashboard = lazy(() =>
  import('@/pages/utility/module/reporting-superset')
);

const LazyBillingDashboard = lazy(() =>
  import('@/pages/utility/module/billing-dashboard')
);

const authRoutes = [
  {
    id: 1,
    navLink: 'verify-mfa',
    component: MfaVerify
  },
  {
    id: 2,
    navLink: 'mfa-setup',
    component: SetupMfa
  },
  {
    id: 3,
    navLink: 'force-change-password',
    component: ForceChangePassword
  }
];

const accessControlPanelRoutes = [
  {
    id: 9,
    title: 'Access Control Panel',
    navLink: 'access-control-panel',
    component: AccessControlPanel
  }
];

const defaultRoutes = [
  ...accessControlPanelRoutes,
  {
    id: 3,
    title: 'PREPAID CONFIGURATION',
    navLink: '/utility/mspdcl/prepaid-configuration',
    icon: PrePaidConfigIcon,
    key: 'mspdcl-preconfig',
    component: LazyPrepaidConfig // Assign lazy-loaded component
  },
  {
    id: 4,
    title: 'PREPAID DASHBOARD',
    navLink: '/utility/mspdcl/prepaid-dashboard',
    icon: PrepaidDashIcon,
    key: 'mspdcl-predash',
    component: LazyPrepaidDashboard // Assign lazy-loaded component
  },
  {
    id: 8,
    title: 'BILLING DASHBOARD',
    navLink: '/utility/mspdcl/billing-dashboard',
    icon: HesIcon,
    key: 'mspdcl-billing-dashboard',
    component: LazyBillingDashboard // Assign lazy-loaded component
  },

  {
    id: 6,
    title: 'DTR & FEEDER MONITORING',
    navLink: '/utility/mspdcl/dt-feeder-monitoring',
    icon: HesIcon,
    key: 'mspdcl-dt-feeder-monitor',
    component: LazyDTFeederMonitoring // Assign lazy-loaded component
  },
  {
    id: 7,
    title: 'DASHBOARD',
    navLink: '/utility/mspdcl/dashboard',
    icon: HesIcon,
    key: 'mspdcl-dashboard',
    component: LazyDashboard // Assign lazy-loaded component
  },
  {
    id: 5,
    title: 'FACTORY PUSH',
    navLink: '/utility/mspdcl/factory-push',
    icon: FacotryFileIcon,
    key: 'mspdcl-factory-push',
    component: LazyFactoryPush // Assign lazy-loaded component
  }
];

export { defaultRoutes, authRoutes, accessControlPanelRoutes };
