// import './App.css';
import Drawer from './components/Drawer';
import Navbar from './components/Navbar';
import SideBar from './components/Navigator';
import '../../styles/layout.scss';
import Footer from './components/Footer';
import { setCollapsed } from '@/app/redux/layoutSlice';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { ChevronRight, ChevronLeft } from 'react-feather';
import { useSelector } from 'react-redux';
function LayoutWrapper({ children }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 991.98px)' });
  const collapsed = useSelector((state) => state.layout.collapsed);
  return (
    <div className="App">
      <Drawer SideBar={SideBar} />
      <div className="layout">
        <div
          className="aside border d-flex"
          onMouseOver={() => {
            !isMobile && dispatch(setCollapsed(false));
          }}
          onMouseOut={() => {
            !isMobile && dispatch(setCollapsed(true));
          }}
        >
          {!isMobile && (
            <>
              <SideBar />
              <div className="navigator-arrow-container d-flex align-items-center w-0">
                <span
                  style={{
                    zIndex: '1000'
                  }}
                >
                  {collapsed ? <ChevronRight /> : <ChevronLeft />}
                </span>
              </div>
            </>
          )}
        </div>
        <Navbar />
        <main id="main" className="webi-scroller">
          <div className="m-2">
            <div style={{ minHeight: '90vh' }}> {children}</div>
            <Footer />
          </div>
        </main>
      </div>
    </div>
  );
}

export default LayoutWrapper;
