import './loader.css'; // Import custom CSS for the spinner

const Loader = ({ height, width, imgSrc, imgAlt }) => (
  <div
    className={`d-flex align-items-center justify-content-center ${
      width ? width : ''
    }`}
    style={{ height: height }}
  >
    <div role="status" className="position-relative">
      <svg
        aria-hidden="true"
        className="custom-spinner"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: '4rem', height: '4rem' }}
      >
        <path
          d="M50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0ZM50 91.5094C27.4013 91.5094 9.08144 73.1895 9.08144 50C9.08144 27.4013 27.4013 9.08144 50 9.08144C72.5987 9.08144 90.9186 27.4013 90.9186 50C90.9186 73.1895 72.5987 91.5094 50 91.5094Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="#7367f0" /* Blue color */
        />
      </svg>
      {imgSrc && (
        <img
          src={imgSrc}
          alt={imgAlt}
          className="position-absolute"
          style={{
            width: '2rem',
            height: '2rem',
            objectFit: 'contain',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </div>
  </div>
);

export default Loader;
