import React, { lazy, Suspense } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import LayoutWrapper from '../../components/layout/LayoutWrapper';
import Loader from '../../components/loader/fallbackLoader';
import Error from '../Error';
import { defaultRoutes, authRoutes } from './Routes';
import PrivateRoute from './ProtectedRoute';

const LazyLogin = lazy(() => import('../login'));
const LazyForgotPassword = lazy(() => import('../forgotPassword'));
const LazyMdmsUtility = lazy(() => import('../utility/module/mdms'));
const LazyHes = lazy(() => import('@/pages/utility/module/hesv1'));

const Router = () => {
  return (
    <HashRouter>
      <Suspense
        fallback={<Loader height={'800px'} imgSrc={'polaris-logo.svg'} />}
      >
        <Routes>
          <Route path="/" element={<LazyLogin />} />
          <Route element={<PrivateRoute />}>
            {defaultRoutes.map((route) => {
              return (
                <Route
                  key={route.key}
                  path={route.navLink}
                  element={
                    <LayoutWrapper>
                      {React.createElement(route.component)}
                    </LayoutWrapper>
                  }
                />
              );
            })}
          </Route>

          {authRoutes.map((e) => (
            <Route
              key={e.id}
              path={e.navLink}
              element={React.createElement(e.component)}
            />
          ))}

          <Route path="forgot-password" element={<LazyForgotPassword />} />
          <Route element={<PrivateRoute />}>
            <Route
              path="utility/mspdcl/hes"
              element={
                <LayoutWrapper>
                  <LazyHes />
                </LayoutWrapper>
              }
            />
            <Route
              path="utility/mspdcl/mdms"
              element={
                <LayoutWrapper>
                  <LazyMdmsUtility />
                </LayoutWrapper>
              }
            />
          </Route>

          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default Router;
